
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { GetStaticProps, GetStaticPropsContext, InferGetStaticPropsType } from "next"
import useTranslation from "next-translate/useTranslation"
import { FC, useState } from "react"

import { HomeContent } from "@app/components/home"
import { Seo } from "@app/components/seo"
import { Layout } from "@app/shared/components/layout"
import { Meta } from "@app/shared/types/seo"
import * as casavoPages from "@shared/utils/pages"

const Home: FC<InferGetStaticPropsType<typeof wrappedGetStaticProps>> = ({ alternates, canonical }) => {
  const { t } = useTranslation()
  const metaTags = t<Meta>(`sell-house:meta`, {}, { returnObjects: true })
  const [heroIsVisible, setHeroIsVisible] = useState(true)

  return (
    <>
      <Seo
        alternates={alternates}
        canonical={canonical}
        og={{
          description: metaTags.description,
          images: [
            { url: (process.env.NEXT_PUBLIC_IMAGE_PATH || "").concat("/images/seo/casavo-og-image.png") },
          ],
          site_name: "Casavo",
          type: "website",
          url: canonical,
        }}
        {...metaTags}
      />

      <Layout bottomSpacer accentColor={heroIsVisible ? "orange" : undefined}>
        <HomeContent updateHeroVisibility={setHeroIsVisible} />
      </Layout>
    </>
  )
}

const wrappedGetStaticProps = async (ctx: GetStaticPropsContext) => {
  if (ctx.locale === casavoPages.DEFAULT_LOCALE) {
    return {
      notFound: true,
      props: {} as never,
    }
  }

  const seoBuilder = casavoPages.seo(process.env.NEXT_PUBLIC_BASE_PATH as string)()
  const alternates = seoBuilder.alternates(ctx.locales as Locale[])
  const canonical = seoBuilder.canonical(ctx.locale as Locale)

  try {
    return {
      props: {
        alternates,
        canonical,
        clientTestimonials: [],
      },
      revalidate: 86400,
    }
  } catch (err) {
    console.log(err)
  }

  return {
    props: { alternates, canonical } as never,
    revalidate: 86400,
  }
}

 const _getStaticProps: GetStaticProps = wrappedGetStaticProps

export default Home


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  